.customButton {
    padding: 5px 15px;
    color: teal;
    font-size: 14px;
    background: transparent;
    border: 1px solid teal;
    cursor: pointer;
    margin-inline-end: 5px;
    margin-top: 5px;
}

.customButton:last-child {
    margin-right: 0;
}

.enableButton {
    padding: 5px 15px;
    color: white;
    font-size: 14px;
    background: teal;
    border: 1px solid teal;
    cursor: pointer;
    margin-inline-end: 5px;
    margin-top: 5px;
}

.disableButton {
    padding: 5px 15px;
    color: teal;
    font-size: 14px;
    background: transparent;
    border: 1px solid teal;
    cursor: pointer;
    margin-inline-end: 5px;
    margin-top: 5px;
}