* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    background-color: #191d25;
}

#root {
}

input {
    color: #b6e3e3;
}

.App {
    margin: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.session-container {
    width: 90%;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.input-content-container{
    width: 100%;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
}

.video-grid {
    display: table-row;
    width:100%;
    max-width:840px;
    margin-top: 20px;
}

.participant {
    display: flex;
    margin-top: 5px;
    border: 2px solid teal;
}

video {
    width: 100%;
    max-width:840px;
}

.user-name {
    position: absolute;
    margin: 20px;
    color: #b6e3e3;
    font-weight: bolder;
    background-color: transparent;
    padding:5px;
    border-radius: 2px;
    font-family: monospace;
    font-size: 18px;
    -webkit-text-stroke: 1px #fff; /* width and color */
}

.buttons {
    display: flex;
    justify-content: center;
}
.powered-by-container {
    grid-column-start: 1;
    grid-column-end: -1;
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.powered-by-text {
    color: #ffffff;
    font-size: 16px;
}
.logo-top {
    background-image: url('../assets/sceenic.svg');
    background-repeat: no-repeat;
    width: 90px;
    height: 57px;
    margin-left: 3px;
    background-size: cover;
    background-position: center;
}
